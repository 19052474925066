import React, { useEffect, useState } from 'react'
import { HeaderWrapper } from './index.styles'
import { Fade, Stack, Typography } from '@mui/material'
import Image from 'next/image'
import useTenant from '@/hooks/useTenant'
import image1 from '@public/images/gm/catering-banner-1.jpg'
import image2 from '@public/images/gm/catering-banner-2.jpg'
import image3 from '@public/images/gm/catering-banner-3.jpg'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'

type Props = {
  isPantryOnly: boolean
}

const VendorOverviewHeader: React.FC<Props> = (props) => {
  const useDynamicMarketplaceHeaderImages = useFeatureFlag(
    SPLITS.USE_DYNAMIC_MARKETPLACE_HEADER_IMAGES,
  )
  const { isPantryOnly } = props
  const tenant = useTenant()

  const marketplaceHeaderImages = tenant.marketing.assets?.marketplaceHeaderImages ?? []

  const [visibleImage, setVisibleImage] = useState(0)
  const images = [image1, image2, image3]

  const marketplaceHeaderImagesToUse = useDynamicMarketplaceHeaderImages
    ? marketplaceHeaderImages
    : images

  useEffect(() => {
    const showNextImage = () => {
      setVisibleImage((prev) => (prev === marketplaceHeaderImagesToUse.length - 1 ? 0 : prev + 1))
    }

    const interval = setInterval(() => {
      showNextImage()
    }, 10000)

    return () => clearInterval(interval)
  }, [setVisibleImage, marketplaceHeaderImagesToUse.length])

  if (!marketplaceHeaderImagesToUse.length) {
    return null
  }

  return (
    <HeaderWrapper>
      {useDynamicMarketplaceHeaderImages
        ? marketplaceHeaderImages.map((image, index) => (
            <Fade appear={false} timeout={1000} key={index} in={index === visibleImage}>
              <Image objectFit="cover" alt="image" src={image} layout="fill" />
            </Fade>
          ))
        : images.map((image, index) => (
            <Fade appear={false} timeout={1000} key={image.src} in={index === visibleImage}>
              <Image objectFit="cover" alt="image" src={image.src} layout="fill" />
            </Fade>
          ))}

      <Stack zIndex={1} position="relative" padding={5} direction="column">
        <Typography color="inverted.main" variant="h2">
          {isPantryOnly ? (
            <span>
              Corporate pantry to
              <br />
              make your office magnetic.
            </span>
          ) : (
            <span>
              Corporate catering that
              <br />
              delivers on every occasion.
            </span>
          )}
        </Typography>

        <Typography color="inverted.main" variant="h5">
          Search our hand picked, taste-tested vendors to find the food you&apos;ve been looking
          for.
        </Typography>
      </Stack>
    </HeaderWrapper>
  )
}

export default VendorOverviewHeader
